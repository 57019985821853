import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import media from "styled-media-query"

const StyledFooter = styled.footer`
  margin-top: 2.45rem;
  margin-bottom: 1rem;
  padding: 10px;
  text-align: center;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    list-style-type: none;

    li {
      &:after {
        content: "|";
        padding: 5px;
      }
      &:last-child {
        &:after {
          content: "";
          padding: 0;
        }
      }
    }
  }

  ${media.lessThan("medium")`
    display: flex;
    flex-direction: column;
    
    ul {
      flex-direction: column;
      
      li {
        margin-bottom: 10px;
        &:after {
          content: "";
          padding: 0;
        }
      }
    }
  `}
`
const StyledLink = styled(Link)`
  text-decoration: none;
`

const Footer = () => (
  <StyledFooter>
    <ul>
      <li>
        <span>© {new Date().getFullYear()} Nicole Behnke</span>
      </li>
      <li>
        <StyledLink to="/" activeClassName="active">
          Startseite
        </StyledLink>
      </li>
      <li>
        <StyledLink to="/impressum/">Impressum</StyledLink>
      </li>
      <li>
        <StyledLink to="/datenschutz/">Datenschutz</StyledLink>
      </li>
    </ul>
  </StyledFooter>
)

export default Footer
